import Link from 'next/link'
import React, { useState } from 'react'
import upperFirst from 'lodash.upperfirst'
import classNames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'

import { useUserSession } from 'hooks'

import Badge from 'components/Badge'
import Button from 'components/Button'
import ButtonLink from 'components/ButtonLink'
import Logo from 'components/Logo'
import Icon from 'components/Icon'

import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'
import GlobalSearch from 'modules/marketplace/search/components/GlobalSearch'
import ModalSidebarContainer from 'modules/marketplace/common/components/ModalSidebarContainer'
import ShopMainSidebar from 'modules/marketplace/catalog/components/ShopMainSidebar'

import GET_SUPER_DEPARTMENTS from './graphql/GetSuperDepartments.graphql'
import { GetSuperDepartments } from './graphql/__generated__/GetSuperDepartments'

import styles from './DepartmentsMenuBar.module.css'
const cx = classNames.bind(styles)

interface DepartmentsMenuBarProps {
  className?: string
}

const DepartmentsMenuBar: React.FC<DepartmentsMenuBarProps> = ({ className }) => {
  const { isGuest, isRep } = useUserSession()
  const router = useRouter()
  const currentQuery = router.query
  const freeShippingQueryString = new URLSearchParams(
    Object.entries({ ...currentQuery, freeShippingOver: 'true' }).filter(
      ([key]) => key !== 'superDepartmentSlug' && key !== 'subDepartmentSlug'
    )
  ).toString()
  const isViewingShopPage = router.pathname.includes('/shop')

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [hideMenu, setHideMenu] = useState(false)

  const handleShowHideMobileMenu = () => {
    setHideMenu(prevState => !prevState)
  }

  const { loading, data } = useQuery<GetSuperDepartments>(GET_SUPER_DEPARTMENTS)
  const departments = data?.superDepartments

  const rightTabs = (
    <>
      {isRep && (
        <div className={styles.repTabsContainer}>
          <Link href="/rh/dashboard">
            <a className={cx(styles.repTabLink, { isActive: router.pathname === '/rh/dashboard' })}>
              <span className={styles.repTabText}>Dashboard</span>
            </a>
          </Link>
          <Link href="/rh/orders">
            <a className={cx(styles.repTabLink, { isActive: router.pathname === '/rh/orders' })}>
              <span className={styles.repTabText}>Orders</span>
            </a>
          </Link>
        </div>
      )}
    </>
  )

  const showFreeShippingBadge =
    (departments?.length ?? 0) > 0 &&
    router.query.freeShippingOver !== 'true' &&
    (isViewingShopPage || router.pathname === '/')

  return (
    <div className={cx(styles.wrapperContainer, className)}>
      <Container>
        <div className={styles.mobileContainer}>
          <div className={cx(styles.mobileMenu, { [styles.hidden]: hideMenu })}>
            <button className={styles.mobileMenuButton} onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
              Menu
              <Icon className={styles.mobileMenuButtonIcon} kind="menu" size={16} />
            </button>
            <ModalSidebarContainer title="Menu" isOpen={isMobileMenuOpen} onExit={() => setMobileMenuOpen(false)}>
              <>
                <div className={styles.mobileMenuSidebarHeader}>
                  <div>
                    {/* This enclosing div ensures the vertical position of the logo is the same as the logo in the HeaderNav */}
                    <Logo />
                  </div>
                  <Button icon="x" kind="transparent" onClick={() => setMobileMenuOpen(false)} />
                </div>
                {isGuest && (
                  <div className={styles.mobileMenuAuthOptions}>
                    <ButtonLink href="/login" label="Login" />
                    <ButtonLink
                      kind="dark"
                      className={styles.signupButton}
                      href="/signup/buyer"
                      label="Signup to Shop"
                    />
                  </div>
                )}
                <div className={styles.mobileMenuSidebarBody}>
                  {isViewingShopPage ? (
                    <ShopMainSidebar isShopExclusive />
                  ) : (
                    <>
                      {isRep && (
                        <>
                          <Link href="/rh/dashboard">
                            <a className={styles.menuOption}>
                              <span className={styles.repTabText}>Dashboard</span>
                            </a>
                          </Link>
                          <Link href="/rh/orders">
                            <a className={styles.menuOption}>
                              <span className={styles.repTabText}>Orders</span>
                            </a>
                          </Link>
                          <hr className={styles.menuSeparator} />
                        </>
                      )}
                      <div className={styles.menuTitle}>{isRep ? 'Departments' : 'Shop Wholesale'}</div>
                      {!loading &&
                        departments?.map(department => (
                          <Link key={department.id} href={`/shop/d/${department.name}`}>
                            <a
                              onClick={() => {
                                setMobileMenuOpen(false)
                              }}
                              className={styles.menuOption}>
                              {upperFirst(department.name)}
                            </a>
                          </Link>
                        ))}
                    </>
                  )}
                </div>
                {isGuest && (
                  <div className={styles.mobileMenuSibebarFooter}>
                    <ButtonLink isBlock href="/wholesaler" label="Sell on �ַ�IV" target="_blank" />
                  </div>
                )}
              </>
            </ModalSidebarContainer>
          </div>
          <div
            className={styles.mobileSearchWrapper}
            onFocus={handleShowHideMobileMenu}
            onBlur={handleShowHideMobileMenu}>
            <GlobalSearch />
          </div>
        </div>

        <div className={styles.container}>
          <div className={styles.departmentsContainer}>
            {departments?.map(department => (
              <Link href={`/shop/d/${department.name}`} key={department.id}>
                <a>
                  <span className={styles.departmentButton}>{upperFirst(department.name)}</span>
                </a>
              </Link>
            ))}

            {showFreeShippingBadge ? (
              <Link href={`${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/shop/d?${freeShippingQueryString}`}>
                <a>
                  <Badge style="tinted" kind="info">
                    Free Shipping
                  </Badge>
                </a>
              </Link>
            ) : null}
          </div>

          {rightTabs}
        </div>
      </Container>
    </div>
  )
}

export default DepartmentsMenuBar
